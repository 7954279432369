import axios from 'axios'
import {
  getAfiliado,
} from '@/utils/paths'

const pathGetAfiliado = getAfiliado

export function afiliadoResource() {
  function findAfiliadoDetalle() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAfiliado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAfiliadoDetalle,
  }
}
