<template>
  <div>
    <validation-observer ref="formSolicitudKit">
      <b-form @submit.prevent="onSubmit">
        <b-overlay
          :show="isWorking"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div v-if="isWorking">
              <div style="text-align: center">
                <span class="spinner-border p-5" />
              </div>
              <div style="text-align: center">
                <h4>{{ overlayConfig.message }}</h4>
              </div>
              <!--<p>Solicitudes: {{ progressData.progressSolicitudes }}</p>
              <p>Solicitudes enviadas{{ progressData.progressSolicitudesDone }}</p>
              -->
              <b-progress
                v-if="progressData.progressSolicitudes > 0"
                :max="progressData.progressSolicitudes"
              >
                <b-progress-bar :value="progressData.progressSolicitudesDone">
                  <span>Solicitudes {{ progressData.progressSolicitudesDone }} / {{ progressData.progressSolicitudes }}</span>
                </b-progress-bar>
              </b-progress>

              <hr>
              <!--<p>Adjuntos: {{ progressData.progressAdjuntos }}</p>
              <p>Adjuntos enviados{{ progressData.progressAdjuntosDone }}</p>-->
              <b-progress
                v-if="progressData.progressAdjuntos > 0"
                :max="progressData.progressAdjuntos"
              >
                <b-progress-bar :value="progressData.progressAdjuntosDone">
                  <span>Adjuntando {{ progressData.progressAdjuntosDone }} / {{ progressData.progressAdjuntos }}</span>
                </b-progress-bar>
              </b-progress>
            </div>
          </template>
          <!--<
            div v-if="!antiguedadAfiliadoValida || !haySolicitudesAGenerar">-->
          <div v-if="!antiguedadAfiliadoValida">
            <b-row class="justify-content-md-center">
              <b-row>
                <b-col md="12">
                  <feather-icon
                    slot="button-content"
                    icon="InfoIcon"
                    size="50"
                  />
                </b-col>
              </b-row>
              <b-row class="justify-content-md-center">
                <b-col
                  md="12"
                  class="mt-1"
                >
                  <span v-if="!antiguedadAfiliadoValida"> Para solicitar el beneficio {{ beneficio.nombre }} ,tu antigüedad como afiliado deben ser mayor a {{ beneficio.antiguedadMinima }} meses</span>
                  <!--<span v-if="!haySolicitudesAGenerar && !beneficioSinProductos"> Este beneficio ya ha sido solicitado, puede controlar el estado del mismo en 'Consulta de Beneficios'</span>-->
                  <span v-if="beneficioSinProductos"> No hay beneficios disponibles, consultar con Atacc</span>
                </b-col>
              </b-row>
            </b-row>
          </div>
          <!--<div v-if="antiguedadAfiliadoValida && haySolicitudesAGenerar && !beneficioSinProductos">-->
          <div v-if="antiguedadAfiliadoValida && !beneficioSinProductos">
            <b-row>
              <b-col
                md="12"
                class="m-0"
              >
                <h4>
                  <b>Solicitud de {{ beneficio.nombre }}</b>
                </h4>
              </b-col>
            </b-row>

            <b-row class="p-0 ">
              <b-col
                md="12"
                class="p-0"
              >
                <b-card-actions
                  v-if="beneficio.mostrarRequisitos && !onlyRead"
                  class="p-0"
                  title="Requisitos para retirar el beneficio"
                  action-collapse
                >
                  <b-form-textarea
                    id="textAreaRequisitos"
                    :value="beneficio.requisitos"
                    no-auto-shrink
                    placeholder="Requisitos"
                    disabled
                    rows="6"
                  />
                </b-card-actions>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <h4>Seleccione el producto para cada persona</h4>
                <b-table
                  id="tblProductos"
                  ref="refTblProductos"
                  :items="solicitudes"
                  :fields="fieldsSolicitantesTable"
                  show-empty
                  small
                  outlined
                  sticky-header="70vh"
                  :no-provider-sorting="false"
                  :no-border-collapse="true"
                  empty-text="No se encontraron resultados para esta búsqueda"
                >
                  <template v-slot:table-busy>
                    <div class="text-center">
                      <b-spinner />
                    </div>
                  </template>
                  <!--:clearable="false"-->

                  <template #cell(fechaNacimiento)="row">
                    {{ formatearFechaNac(row) }}
                  </template>
                  <template #cell(dni)="row">
                    <span :style="`color: ${getColorEstado(row.item.valido)}`">
                      {{ dniSolicitud(row.item) }}
                    </span>
                    <font-awesome-icon
                      v-if="row.item.valido !== null &&!row.item.valido"
                      v-b-tooltip.hover="'Sin datos de DNI, por favor consulte con su delegación'"
                      icon="fa-solid fa-circle-info"
                    />
                  </template>
                  <template #cell(listaProductos)="row">
                    <!--{{ row.item }}-->
                    <b-form-select
                      v-if="isSolicitarPosible(row)"
                      v-model="row.item.productoId"
                      :options="row.item.listaProductos"
                      value-field="id"
                      text-field="nombre"
                      :disabled="!row.item.valido"
                      size="sm"
                    />
                    <small v-if="!isSolicitarPosible(row) && !isTipoConProductoConPaniales()"> Solicitud ya generada </small>
                    <small v-if="!isSolicitarPosible(row) && isTipoConProductoConPaniales() && !row.item.edadValida">Excede la edad máxima permitida ({{ beneficio.edadMaxima }} meses). </small>
                    <small v-if="!isSolicitarPosible(row) && isTipoConProductoConPaniales() && row.item.previaPendiente">Tiene una solicitud Previa en estado pendiente. </small>
                    <small v-if="!isSolicitarPosible(row) && isTipoConProductoConPaniales() && row.item.superaEntregas">Entregas máxima alcanzada {{ row.item.cantEntregasActuales }}. </small>
                    <font-awesome-icon
                      v-if="!isSolicitarPosible(row) && !isTipoConProductoConPaniales()"
                      v-b-tooltip.hover.right="detalles(row.item)"
                      icon="fa-solid fa-circle-info"
                    />
                  </template>
                  <template #cell(actions)="row">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <!-- row.index, $event.target-->
                      <b-button
                        v-if="permisosVer.includes(nameModulo) && beneficio.solicitarAdjuntos"
                        v-b-tooltip.hover.righttop="row.item.solicitudGenerada ? 'Ver adjuntos': botonTooltip(row.item)"
                        size="sm"
                        :variant="botonVariant(row.item)"
                        :disabled="row.item.valido!== null && !row.item.valido || !puedeAdjuntar(row.item)"
                        @click="adjuntar(row.item)"
                      >
                        <font-awesome-icon
                          v-if="!row.item.solicitudGenerada"
                          icon="fa-solid fa-file-arrow-up"
                        />
                        <font-awesome-icon
                          v-if="row.item.solicitudGenerada"
                          icon="fa-regular fa-eye"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <br>
            <b-row v-if="!onlyRead">
              <b-col md="4">
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Retiro del beneficio"
                >
                  <b-form-radio
                    v-model="formSolicitudesDto.retiraUsuario"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="true"
                    @input="limpiarDatosRetiraOtro"
                  >
                    Yo lo retiraré
                  </b-form-radio>
                  <br>
                  <b-form-radio
                    v-model="formSolicitudesDto.retiraUsuario"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="false"
                  >
                    Otra persona lo retirará
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="!formSolicitudesDto.retiraUsuario"
              class="ml-2"
            >
              <b-col md="2">
                <b-form-group
                  id="frmRetiraDni"
                  label="DNI"
                >
                  <validation-provider
                    name="retiraDni"
                    rules="required|max:8"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="inputDni"
                        v-model="formSolicitudesDto.retiraDni"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        type="number"
                        placeholder=""
                        @input="(e) => checkOnlyNumber(e)"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >DNI obligatorio.
                      </small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 8 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="frmRetiraApellido"
                  label="Apellido"
                >
                  <validation-provider
                    name="retiraApellido"
                    rules="required|max:50"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtRetiraApellido"
                        v-model="formSolicitudesDto.retiraApellido"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Apellido obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 50 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="frmRetiraNombre"
                  label="Nombre"
                >
                  <validation-provider
                    name="retiraNombre"
                    rules="required|max:50"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtRetiraNombre"
                        v-model="formSolicitudesDto.retiraNombre"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 50 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </div>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnBackPanelBeneficios"
                v-b-tooltip.hover
                title="Volver al panel de benficios"
                variant="light"
                squared
                :disabled="isWorking"
                @click="()=>backPanelBeneficios()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="showSave"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Generar solicitudes"
                type="submit"
                :disabled="isWorking"
                @click.prevent="validationFormSolicitudKit"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>

      </b-form>
    </validation-observer>
    <div v-if="showModalUploadFile">
      <modal-upload-file
        :show-modal-upload-file="showModalUploadFile"
        :identificador-emit="identificadorEmit"
        :item-solicitud="itemSolicitud"
        :adjuntos-list="adjuntosList"
        titulo-modal="Adjuntos"
        @closemodal="closemodal"
        @contentModalUploadFile="contentModalUploadFile"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  required, confirmed, email, max,
  min,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { solicitudResource } from '@/services/solicitudResource'
import ModalUploadFile from '@/views/beneficios/solicitud/ModalUploadSolicitud.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { saveAs } from 'file-saver'
// import { fileManagement } from '@/utils/fileManagement'
import {
  BENE_TIPO_CON_PRODUCTO,
  BENE_TIPO_CON_PRODUCTO_PERPETUO,
  BENE_TIPO_QUINCHO,
  BENE_TIPO_CON_PRODUCTO_PANIALES,
} from '@/utils/codigoTipoBeneConst'
import {
  SOL_ESTADO_APROBADA,
  SOL_ESTADO_PENDIENTE,
  SOL_ESTADO_RECHAZADA,
  SOL_ESTADO_CANCELADA,
  SOL_ESTADO_ENTREGADA,
  SOL_ESTADO_INCOMPLETA,
} from '@/utils/codigoEstado'

import {
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardActions,
    ValidationProvider,
    ValidationObserver,
    ModalUploadFile,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    beneficio: {
      type: Object,
      required: true,
      default: undefined,
    },
    solicitudesPrevias: {
      type: Array,
      required: true,
      default: undefined,
    },
    categoriaSolicitante: {
      type: Array,
      required: true,
      default: undefined,
    },
    afiliado: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      showModalUploadFile: false,

      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '1',
        blur: '5px',
        message: '',
      },
      swallConfig: {
        background: 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)',
        textColor: 'white',
      },
      modalShow: false,
      itemSolicitud: null,
      identificadorEmit: null,
      adjuntosList: [],
      solicAdjuntosList: [],
      isCollapsed: false,
      modaltitle: '',
      modalMessage: '',
      selected: true,
      isWorking: false,
      required,
      confirmed,
      email,
      max,
      min,
      cargando: false,
      cargandoRoles: false,
      nameModulo: 'SOLICITUD_BENEFICIO',
      beneficioDto: null,
      afiliadoDetalle: null,
      candidatosSolicitudLista: [],
      solicitudes: [],
      formSolicitudesDto: {
        usuarioId: null,
        dni: null,
        productoId: null,
        listaProductos: [],

        estadoCodigo: null,
        retiraUsuario: true,
        // retiraOtro: false,
        retiraDni: null,
        retiraNombre: null,
        retiraApellido: null,

      },
      adjuntosDetalleProcesamientoAdjunto: [],
      existenErroresVerificacionSolicitudes: false,
      fieldsSolicitantesTable: [
      ],
      progressData: {
        progressSolicitudes: 0,
        progressSolicitudesDone: 0,
        progressAdjuntos: 0,
        progressAdjuntosDone: 0,
      },
      BENE_TIPO_CON_PRODUCTO,
      BENE_TIPO_CON_PRODUCTO_PERPETUO,
      BENE_TIPO_QUINCHO,
      BENE_TIPO_CON_PRODUCTO_PANIALES,
      SOL_ESTADO_APROBADA,
      SOL_ESTADO_PENDIENTE,
      SOL_ESTADO_RECHAZADA,
      SOL_ESTADO_CANCELADA,
      SOL_ESTADO_ENTREGADA,
      SOL_ESTADO_INCOMPLETA,
    }
  },
  computed: {
    onlyRead() {
      return !this.haySolicitudesAGenerar && !this.beneficioSinProductos
    },
    beneficioSinProductos() {
      let sinProducto = false
      if (this.beneficio.beneficioProducto.length === 0) {
        sinProducto = true
      }
      if (this.beneficio.beneficioProducto.length > 0) {
        if (!this.beneficio.beneficioProducto.some(benPrdo => benPrdo.esActivo === true)) {
          sinProducto = true
        }
      }
      return sinProducto
    },
    showSave() {
      return this.permisosIns.includes(this.nameModulo) && this.antiguedadAfiliadoValida && this.haySolicitudesAGenerar
    },
    haySolicitudesAGenerar() {
      let haySolicPorGenerar = true
      const arraySolicitudesAgenerar = this.solicitudes.filter(solic => solic.solicitudGenerada === false)
      if (arraySolicitudesAgenerar.length === 0) {
        haySolicPorGenerar = false
      }
      return haySolicPorGenerar
    },
    antiguedadAfiliadoValida() {
      let estado = true
      let mesesDiff = null
      let antiguedadMin = null
      let startDate = null
      let endDate = null

      if (this.beneficio.controlarAntiguedad) {
        const afiliadoFechaAlta = `${this.afiliado.statusDate} 00:00`
        const fechaAlta = new Date(afiliadoFechaAlta)

        let fechaEntrega = null
        if (this.isTipoConProducto()) {
          fechaEntrega = this.formatDateTime(this.beneficio.fechaEntrega, 'YYYY-MM-DD HH:mm', 'DD-MM-YYYY HH:mm')
        } else if (this.isTipoConProductoPerpetuo() || this.isTipoConProductoConPaniales()) {
          fechaEntrega = this.formatDateTime(new Date(), 'YYYY-MM-DD HH:mm', 'DD-MM-YYYY HH:mm')
        }

        // fechaEntrega.setHours(0, 0, 0, 0)
        const date1 = fechaAlta
        const date2 = new Date(fechaEntrega)
        startDate = date1
        endDate = date2
        antiguedadMin = this.beneficio.antiguedadMinima
        const roundUpFractionalMonths = true
        startDate = date1
        endDate = date2
        let inverse = false
        if (date1 > date2) {
          startDate = date2
          endDate = date1
          inverse = true
        }

        // Calculate the differences between the start and end dates
        const yearsDifference = endDate.getFullYear() - startDate.getFullYear()
        const monthsDifference = endDate.getMonth() - startDate.getMonth()
        const daysDifference = endDate.getDate() - startDate.getDate()

        let monthCorrection = 0
        // If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
        // The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
        if (roundUpFractionalMonths === true && daysDifference > 0) {
          monthCorrection = 1
        } else if (roundUpFractionalMonths !== true && daysDifference < 0) {
          // If the day difference between the 2 months is negative, the last month is not a whole month.
          monthCorrection = -1
        }

        // return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection)
        mesesDiff = (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection)
        if (mesesDiff <= antiguedadMin) {
          estado = false
        }
      }

      return estado
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
    nombreUsuario() {
      if (this.currentUser) {
        return `${this.currentUser.nombre} ${this.currentUser.apellido}`
      }
      return 'S/D'
    },
  },
  created() {
    this.buildFiledSilicitantesTable()
    this.buildArrayCandidatosASolicitud()
  },
  methods: {
    isSolicitudAprobada(solicitud) {
      return solicitud.solicitudEstado.codigo === SOL_ESTADO_APROBADA
    },
    isSolicitudPendiente(solicitud) {
      return solicitud.solicitudEstado.codigo === SOL_ESTADO_PENDIENTE
    },
    isSolicitudEntregada(solicitud) {
      return solicitud.solicitudEstado.codigo === SOL_ESTADO_ENTREGADA
    },
    isTipoConProducto() {
      if (this.beneficio.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO) {
        return true
      }
      return false
    },
    isTipoConProductoPerpetuo() {
      if (this.beneficio.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
        return true
      }
      return false
    },
    isTipoQuincho(codigo) {
      if (codigo === BENE_TIPO_QUINCHO) {
        return true
      }
      return false
    },
    isTipoConProductoConPaniales() {
      if (this.beneficio.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
        return true
      }
      return false
    },
    setSwallColor() {
      const { skin } = useAppConfig()
      if (skin.value === 'dark') {
        // this.swallConfig.background = 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)'
        this.swallConfig.background = '#1A2F50'

        this.swallConfig.textColor = 'white'
      } else {
        // this.swallConfig.background = 'linear-gradient(325deg, #ffffff 0%, #ffffff 100%)'
        this.swallConfig.background = '#FFFFFF)'
        this.swallConfig.textColor = 'black'
      }
    },
    dniSolicitud(item) {
      return item.dni != null ? item.dni : 'DNI obligatorio'
    },
    getColorEstado(valido) {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      let color = whiteBlack
      if (valido === false) {
        // Rechazada
        color = '#FC1C1C'
      } else {
        color = 'grey'
      }
      return color
    },
    formatearFechaNac(row) {
      let fecha = '-'
      if (row.item.fechaNacimiento !== null) {
        fecha = this.formatDateTable(row.item.fechaNacimiento, 'DD/MM/YYYY')
      }
      return fecha
    },
    detalles(item) {
      let text = ''
      if (!this.isTipoConProductoConPaniales()) {
        text = `<strong>${item.producto}</strong> solicitado por <br>${item.usuario.apellido}, ${item.usuario.nombre} <br> el día ${this.formatDateTime(item.fechaSolicitud, 'DD/MM/YYYY')}`
      } else {
        text = 'edad o cantidad de entregadas'
      }
      const options = {
        title: text,
        html: true,
      }
      return options
    },
    isEdadValida(sol) {
      let estado = false
      let mesesDiff = null
      let edadMaxima = null
      let startDate = null
      let endDate = null
      if (this.beneficio.controlarEdad) {
        const fechaNacimientoHijo = new Date(`${sol.fechaNacimiento} 00:00`)
        const currentDate = new Date()
        // fechaEntrega.setHours(0, 0, 0, 0)
        const date1 = fechaNacimientoHijo
        const date2 = currentDate
        startDate = date1
        endDate = date2
        edadMaxima = this.beneficio.edadMaxima
        const roundUpFractionalMonths = true
        startDate = date1
        endDate = date2
        let inverse = false
        if (date1 > date2) {
          startDate = date2
          endDate = date1
          inverse = true
        }

        // Calculate the differences between the start and end dates
        const yearsDifference = endDate.getFullYear() - startDate.getFullYear()
        const monthsDifference = endDate.getMonth() - startDate.getMonth()
        const daysDifference = endDate.getDate() - startDate.getDate()

        let monthCorrection = 0
        // If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
        // The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
        if (roundUpFractionalMonths === true && daysDifference > 0) {
          monthCorrection = 1
        } else if (roundUpFractionalMonths !== true && daysDifference < 0) {
          // If the day difference between the 2 months is negative, the last month is not a whole month.
          monthCorrection = -1
        }

        // return (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection)
        mesesDiff = (inverse ? -1 : 1) * (yearsDifference * 12 + monthsDifference + monthCorrection)
        if (mesesDiff <= edadMaxima) {
          estado = true
        }
      }
      return estado
    },
    buildFiledSilicitantesTable() {
      this.fieldsSolicitantesTable.push(
        { key: 'nombre', label: 'Nombre' },
        { key: 'fechaNacimiento', label: 'Fecha nacimiento' },
        { key: 'dni', label: 'DNI' },
        { key: 'relacion', label: 'Relacion' },
        { key: 'listaProductos', label: 'beneficio' },
      )
      if (this.beneficio.solicitarAdjuntos) {
        this.fieldsSolicitantesTable.push({ key: 'actions', label: 'Adjunto' })
      }
    },
    closemodal() {
      this.identificadorEmit = null
      this.itemSolicitud = null
      this.showModalUploadFile = false
    },
    contentModalUploadFile({ adjuntos, identificador }) {
      // contentModalUploadFile
      const pos = this.solicitudes.findIndex(solicitud => solicitud.dni === identificador)
      this.solicitudes[pos].solicitudAdjunto = []
      adjuntos.forEach(adjunto => {
        this.solicitudes[pos].solicitudAdjunto.push(adjunto)
      })

      // this.solicitudes[pos].solicitudAdjunto = [...adjuntos]
      this.identificadorEmit = null
      this.showModalUploadFile = false
    },
    // adjuntar(item, index, target) {
    adjuntar(item) {
      this.itemSolicitud = item
      this.identificadorEmit = item.dni
      this.adjuntosList = item.solicitudAdjunto
      this.showModalUploadFile = true
    },
    botonVariant(elemento) {
      if (elemento.productoId == null || elemento.solicitudGenerada) {
        return 'secondary' // Si no hay producto seleccionado, el botón es gris
      } if (elemento.solicitudAdjunto.length > 0) {
        return 'success' // Si hay archivo adjunto, el botón es verde
      }
      return 'danger'// Si falta adjuntar archivo, el botón es rojo
    },
    puedeAdjuntar(elemento) {
      if (elemento.productoId == null) {
        return false
      }
      return true // Devuelve true si hay un producto seleccionado
    },
    botonTooltip(elemento) {
      if (elemento.productoId == null) {
        return 'Seleccione un producto' // Tooltip cuando no hay producto seleccionado
      } if (elemento.solicitudAdjunto.length > 0) {
        return 'Tiene archivo adjunto' // Tooltip cuando hay archivo adjunto
      }
      return 'Falta adjuntar archivo' // Tooltip cuando falta adjuntar archivo
    },
    buildArrayCandidatosASolicitud() {
      // Paso 1 - Armamos el listado de los candidatos a solicitudes en base a servicio WS
      // teniendo en cuenta tanto al afiliado como al array affiliateFamily que contiene conyuge e hijos.
      // 1a - Armamos los datos del afiliados.
      this.armarCandidatoConDatosAfiliado()

      // 1b - Armamos los datos de cada item en el array affiliateFamily como posibles candidatos a solicitar
      this.armarCandidatosConDatosFamiliaAfiliado()

      // Paso 2 - generamos una listado con todos los tipos de beneficiaros """en este beneficio""", independiente de que producto sea
      // Esto nos servirá para armar un listado de aquellos solicitantes qye esten presetenes segun relacion (afiliado, hijo, conyuge)
      const tipoBeneficiariosPresentesEnProductos = []
      this.beneficio.beneficioProducto.forEach(beneProd => {
        beneProd.categoriaSolicitante.forEach(categSolic => {
          tipoBeneficiariosPresentesEnProductos.push(categSolic.nombreCustomExterno)
        })
      })

      // Paso 3 - Agregamos primero las solicitudes que tenemos en base de datos (previas)
      // Salvo que sea producto con pañales, porque en un paso posterior deberemos validad multiples previos.
      if (this.solicitudesPrevias.length > 0 && !this.isTipoConProductoConPaniales()) {
        this.solicitudesPrevias.forEach(solicPrevia => {
          const solicitud = {
            nombre: solicPrevia.beneficiarioNombre,
            fechaNacimiento: solicPrevia.beneficiarioFechaNac,
            relacion: solicPrevia.categoriaSolicitante.nombreExterno,
            productoId: solicPrevia.producto.id,
            listaProductos: [],
            dni: solicPrevia.beneficiarioDni,
            solicitudGenerada: true,
            solicitudAdjunto: [],
            id: solicPrevia.id,
            usuario: solicPrevia.usuario,
            fechaSolicitud: solicPrevia.fechaSolicitud,
            solicitanteNroAfiliado: solicPrevia.solicitanteNroAfiliado,
            producto: solicPrevia.producto.nombre,
            valido: null,
          }
          this.solicitudes.push(solicitud)
        })
      }
      // Paso 4 -
      // Este es el paso donde verificamos que si ya existe entonces no se agraga una nueva a ser solicitada.
      // O sea, en este bloque vemos los candidatos a solicitud, y por cada uno verificamos si existe
      // en solicitudes previas. Si existe, no agregamos una solicitud "nueva" para la tabla sino que dejamos
      // la solicitud previa (que se generó en el paso anterior (paso 3)) y la marcamos como generada.
      // Si no existe en solicitudes previas entonces SI
      // agregamos una solicitud para poder ser generada.s

      this.verificarYCompletarSolicitudes(tipoBeneficiariosPresentesEnProductos)

      // Paso 5 final
      // Ahora que tenemos las solicitudes, veremos que productos tiene disponible cada una para ser seleccionado.
      this.solicitudes.forEach(solicitud => {
        this.beneficio.beneficioProducto.forEach(beneProd => {
          beneProd.categoriaSolicitante.forEach(cat => {
            if (cat.nombreCustomExterno === solicitud.relacion) {
              const producto = {
                id: null,
                nombre: null,
              }
              producto.id = beneProd.producto.id
              producto.nombre = beneProd.producto.nombre
              solicitud.listaProductos.push(producto)
            }
          })
        })
      })
    },
    verificarYCompletarSolicitudes(tipoBeneficiariosPresentesEnProductos) {
      this.candidatosSolicitudLista.forEach(candsolic => {
        const incluido = tipoBeneficiariosPresentesEnProductos.includes(candsolic.relacion)
        if (incluido) {
          const solicitud = {
            nombre: null,
            fechaNacimiento: null,
            relacion: null,
            productoId: null,
            listaProductos: [{ id: null, nombre: 'Seleccione ...' }],
            dni: null,
            solicitanteDeleExtCode: null,
            solicitanteDelegacion: null,
            solicitudGenerada: false,
            solicitanteNroAfiliado: null,
            solicitudAdjunto: [],
          }
          if (candsolic.solicitanteDeleExtCode !== null) {
            solicitud.solicitanteDeleExtCode = candsolic.solicitanteDeleExtCode
          }
          if (candsolic.solicitanteDelegacion !== null) {
            solicitud.solicitanteDelegacion = candsolic.solicitanteDelegacion
          }
          // nicolas
          solicitud.nombre = candsolic.nombre
          solicitud.fechaNacimiento = candsolic.fechaNacimiento
          solicitud.relacion = candsolic.relacion
          solicitud.dni = candsolic.dni
          solicitud.valido = candsolic.valido
          solicitud.solicitanteNroAfiliado = candsolic.solicitanteNroAfiliado
          solicitud.solicitudGenerada = false
          solicitud.superaEntregas = false
          solicitud.cantEntregasActuales = 0
          solicitud.previaPendiente = false
          solicitud.edadValida = false
          // Verificamos si ya tiene una solicitud ya generada
          if (this.beneficio.beneficioTipo.codigo !== BENE_TIPO_CON_PRODUCTO_PANIALES) {
            if (this.solicitudesPrevias.some(solPrev => solPrev.beneficiarioDni === solicitud.dni)) {
              solicitud.solicitudGenerada = true
            }
            if (!solicitud.solicitudGenerada) {
              this.solicitudes.push(solicitud)
            }
          } else {
            // Tratamiento productos con pañales
            if (this.beneficio.controlarCantEntregas) {
              const previaPendienteCant = this.solicitudesPrevias.filter(solPrev => solPrev.beneficiarioDni === solicitud.dni && this.isSolicitudPendiente(solPrev)).length
              if (previaPendienteCant > 0) {
                solicitud.previaPendiente = true
                solicitud.solicitudGenerada = true
              }
              const cantEntregada = this.solicitudesPrevias.filter(solPrev => solPrev.beneficiarioDni === solicitud.dni && this.isSolicitudEntregada(solPrev)).length
              if (cantEntregada >= this.beneficio.cantEntregas) {
                solicitud.cantEntregasActuales = cantEntregada
                solicitud.superaEntregas = true
                solicitud.solicitudGenerada = true
              }
            }
            if (this.beneficio.controlarEdad) {
              if (this.isEdadValida(solicitud)) {
                solicitud.edadValida = true
              } else {
                solicitud.solicitudGenerada = true
              }
            }
            this.solicitudes.push(solicitud)
          }
        }
      })
    },
    isSolicitarPosible(row) {
      let estado = true
      if (this.beneficio.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
        if (row.item.relacion === 'Hijo') {
          if (this.beneficio.controlarEdad) {
            if (!row.item.edadValida) {
              estado = false
            }
          }
          if (this.beneficio.controlarCantEntregas) {
            if (row.item.superaEntregas) {
              estado = false
            }
          }

          if (row.item.previaPendiente) {
            estado = false
          }
        }
      } else if (this.beneficio.beneficioTipo.codigo === BENE_TIPO_CON_PRODUCTO) {
        // return !row.item.solicitudGenerada
        if (row.item.solicitudGenerada) {
          estado = false
        }
      } else {
        estado = true
      }
      return estado
    },
    armarCandidatoConDatosAfiliado() {
      const solicitante = {
        nombre: null,
        dni: null,
        solicitanteNroAfiliado: null,
        fechaNacimiento: null,
        relacion: null,
        solicitanteDeleExtCode: null,
        solicitanteDelegacion: null,
        valido: false,
      }
      solicitante.nombre = this.afiliado.name
      solicitante.solicitanteNroAfiliado = this.afiliado.code
      if (this.afiliado.identity && this.afiliado.identity !== null) {
        solicitante.dni = this.afiliado.identity
        solicitante.valido = true
      } else {
        solicitante.dni = null
      }

      solicitante.fechaNacimiento = this.afiliado.birthdate
      solicitante.relacion = 'Afiliado'
      solicitante.solicitanteDeleExtCode = null
      solicitante.solicitanteDelegacion = null

      if (this.affiliateDelegationCode !== null) {
        solicitante.solicitanteDeleExtCode = this.afiliado.affiliateDelegationCode
      }
      if (this.affiliateDelegationName !== null) {
        solicitante.solicitanteDelegacion = this.afiliado.affiliateDelegationName
      }
      // Agregamos este candidato a asolicitar en candidatosSolicitudLista
      this.candidatosSolicitudLista.push(solicitante)
    },
    armarCandidatosConDatosFamiliaAfiliado() {
      if (this.afiliado.affiliateFamily !== null && this.afiliado.affiliateFamily.length > 0) {
        this.afiliado.affiliateFamily.forEach(af => {
          const solicitanteFamilia = {
            nombre: `${af.name}`,
            dni: null,
            fechaNacimiento: af.birthdate,
            relacion: af.familyRelationshipName,
            valido: false,
            solicitanteNroAfiliado: null,
          }
          solicitanteFamilia.solicitanteNroAfiliado = this.afiliado.code
          if (af.identity && af.identity !== null) {
            solicitanteFamilia.dni = af.identity
            solicitanteFamilia.valido = true
          } else {
            solicitanteFamilia.dni = null
          }
          if (this.afiliado.affiliateDelegationCode !== null) {
            solicitanteFamilia.solicitanteDeleExtCode = this.afiliado.affiliateDelegationCode
          }
          if (this.affiliateDelegationName !== null) {
            solicitanteFamilia.solicitanteDelegacion = this.afiliado.affiliateDelegationName
          }
          this.candidatosSolicitudLista.push(solicitanteFamilia)
        })
      }
    },
    generarSolicitudes() {
      this.isWorking = true
      this.overlayConfig.message = 'Generando solicitudes ...'
      const dtos = this.generarArrayDtos()
      this.progressData.progressSolicitudes = dtos.length
      // const progressAdjuntos = 0
      dtos.forEach(dto => {
        this.progressData.progressAdjuntos += dto.solicitudAdjunto.length
      })

      this.guardarSolicitudes(dtos).then(() => {
        if (this.solicAdjuntosList.length > 0) {
          this.guardarAdjuntos(this.solicAdjuntosList).then(() => {
          }).catch(error => {
            console.error('error', error)
          }).finally(() => {
            this.verificarGeneracionSol()
          })
        } else {
          this.isWorking = false
          this.showMessageSolicitudesGeneradas('Solicitudes', 'success', 'Solicitudes generadas.')
        }
      }).catch(error => {
        this.isWorking = false
        this.showMessageSolicitudesGeneradas('Solicitudes', 'error', 'Errores durante la generacion de solicitudes. Por favor vuelva a intentar mas tarde. Si el error persiste, comuníquese con su delegación.')
        console.error('terminado en error', error)
      }).finally(() => {
      })
    },
    verificarGeneracionSol() {
      const detalleErrores = []
      this.existenErroresVerificacionSolicitudes = false
      this.adjuntosDetalleProcesamientoAdjunto.forEach(solicitudAdjuntoElement => {
        if (solicitudAdjuntoElement.adjuntosFail.length > 0) {
          this.existenErroresVerificacionSolicitudes = true
          const detalleError = {
            beneficiario: null,
            adjuntosNombre: [],
          }
          detalleError.beneficiario = solicitudAdjuntoElement.solicitud.beneficiarioNombre
          solicitudAdjuntoElement.adjuntosFail.forEach(adjuntoConError => {
            const adjuntoDetail = {
              nombreAdjunto: adjuntoConError.nombreAdjunto,
              adjuntoSize: adjuntoConError.adjuntoSize,
            }
            detalleError.adjuntosNombre.push(adjuntoDetail)
          })
          detalleErrores.push(detalleError)
        }
      })
      solicitudResource().verificarycompletarsolicitud(this.adjuntosDetalleProcesamientoAdjunto).then(() => {
        if (!this.existenErroresVerificacionSolicitudes) {
          this.showMessageSolicitudesGeneradas('Solicitudes', 'success', 'Solicitudes generadas!')
        }

        this.isWorking = false
      }).catch(error => {
        console.error('Error al verificar solicitudes', error)
        this.isWorking = false
        if (error === undefined) {
          this.showMessageSolicitudesGeneradas('Error en solicitudes', 'error', 'No se pudo verificar la generación de solicitudes. Por favor, intente nuevamente. Si el error persiste, comuníquese con su delegación.')
        }
      })
        .finally(() => {
          if (this.existenErroresVerificacionSolicitudes) {
            this.showMessageSolicitudesGeneradas('Error en solicitudes', 'error', `No se pudo adjuntar algunos archivos: ${this.buildStringMessage(detalleErrores)}`)
          }
        })
    },
    buildStringMessage(detalleErrores) {
      let message = '<ul>'
      this.setSwallColor()
      detalleErrores.forEach(item => {
        message += `<li style="color: ${this.swallConfig.textColor}"> ${item.beneficiario} <br>`
        message += 'Adjuntos: <br>'
        item.adjuntosNombre.forEach(adjunto => {
          message += ` ${adjunto.nombreAdjunto} <br>`
        })
        message += '</li>'
      })
      message += '</ul>'
      message += `<br> <p style="color: ${this.swallConfig.textColor}">Intente nuevamente solicitar y adjuntar para los beneficiarios indicados.</p>`
      return message
    },
    guardarSolicitudes(dtos) {
      const localthis = this
      let index = 0
      function request() {
        const dtoSinAdjuntos = (dtopas => {
          const dtoMod = { ...dtopas }
          dtoMod.solicitudAdjunto = null
          return dtoMod
        })
        return localthis.guardarSolicitud(dtoSinAdjuntos(dtos[index])).then(resultado => {
          if (dtos[index].solicitudAdjunto.length > 0) {
            const solAdjuntos = {
              idSolicitud: resultado.id,
              beneficiarioNombre: resultado.beneficiarioNombre,
              solicitanteCuil: resultado.solicitanteCuil,
              adjuntos: dtos[index].solicitudAdjunto,
            }
            localthis.solicAdjuntosList.push(solAdjuntos)
          }

          index += 1
          localthis.progressData.progressSolicitudesDone += 1
          if (index >= dtos.length) {
            return 'done'
          }
          return request()
        })
      }
      return request()
    },
    guardarAdjuntos(solicAdjuntosList) {
      const solAdjList = []
      solicAdjuntosList.forEach(element => {
        /* const soladjuntos = {
          id: element.id,
          solicitudRes: element,
          adjuntos: element.adjuntos.map(adj => adj.adjunto),
        } */
        // solAdjList.push(soladjuntos)
        solAdjList.push(element)
      })
      const localthis = this
      let index = 0
      function request() {
        return localthis.guardarMultiplesAdjuntos(solAdjList[index], solAdjList[index].adjuntos).then(() => {
          index += 1
          if (index >= solAdjList.length) {
            return 'done'
          }
          return request()
        })
      }
      return request()
    },
    guardarSolicitud(solicitudDtoSinAdjunto) {
      return solicitudResource().generarSolicitud(solicitudDtoSinAdjunto)
    },
    guardarAdjunto(id, adjunto) {
      return solicitudResource().uploadFileAttachment(id, adjunto.adjunto)
    },
    guardarMultiplesAdjuntos(soldAdjuntoItem, adjuntos) {
      const promesas = []
      // Armamos el dto que será enviado a backend para controlar si los adjuntos fueron exitosos para marcar como pendientes.
      // En el backend, si hay problemas con un adjunto, la solicitud se debe marcar como incompleta, y se debería borrar aquellos adjuntos
      // asociados a esa solicitud para no consumir espacio incnecesario en la base de datos.
      const resultingSolicitudAndAdjunto = {}
      resultingSolicitudAndAdjunto.idSolicitud = soldAdjuntoItem.idSolicitud
      resultingSolicitudAndAdjunto.solicitud = soldAdjuntoItem
      resultingSolicitudAndAdjunto.adjuntosSuccess = []
      resultingSolicitudAndAdjunto.adjuntosFail = []

      adjuntos.forEach(adjunto => {
        const promesa = this.guardarAdjunto(soldAdjuntoItem.idSolicitud, adjunto).then(respuesta => {
          this.progressData.progressAdjuntosDone += 1
          // Para no modificar el obhjeto por parametro, creamos uno nuevo y le asignamos el ID que genero el backend para el adjunto.
          const adj = { ...adjunto }
          adj.id = respuesta.idAdjunto
          resultingSolicitudAndAdjunto.adjuntosSuccess.push(adj)
        }).catch(error => {
          resultingSolicitudAndAdjunto.adjuntosFail.push(adjunto)
          console.error(error)
        })

        promesas.push(promesa)
      })
      this.adjuntosDetalleProcesamientoAdjunto.push(resultingSolicitudAndAdjunto)
      // this.adjuntosDetalleProcesamientoAdjunto = { ...resultingSolicitudAndAdjunto }
      return Promise.allSettled(promesas)
    },
    guardarMultiplesAdjuntosOLD(id, adjuntos) {
      return solicitudResource().uploadFilesAttachment(id, adjuntos)
    },
    generarArrayDtos() {
      const dtos = []
      this.solicitudes.forEach(solic => {
        if (solic.productoId !== null && solic.solicitudGenerada === false) {
          const dto = {
            id: null,
            usuarioId: null,
            beneficioId: null,
            productoId: null,
            solicitudEstadoId: null,
            solicitanteCuil: null,
            categoriaSolicitante: this.categoriaSolicitante.filter(obj => obj.nombreExterno === solic.relacion)[0],
            beneficiarioNombre: null,
            beneficiarioDni: null,
            beneficiarioFechaNac: null,
            retiraUsuario: null,
            // retiraOtro: null,
            retiraDni: null,
            retiraNombre: null,
            retiraApellido: null,
            solicitanteDeleExtCode: null,
            solicitanteDelegacion: null,
            solicitanteNroAfiliado: null,
            solicitudAdjunto: [],
          }
          dto.beneficioId = this.beneficio.id
          dto.productoId = solic.productoId
          dto.solicitudEstadoId = null
          dto.solicitanteCuil = this.currentUser.cuil
          dto.beneficiarioNombre = solic.nombre
          dto.beneficiarioDni = solic.dni
          dto.beneficiarioFechaNac = this.formatDateToJavaT(solic.fechaNacimiento, 'YYYY-MM-DD', 'YYYY-MM-DD HH:mm')
          // dto.beneficiarioFechaNac = this.formatDateToJavaT(solic.fechaNacimiento, 'YYYY-MM-DD', 'DD-MM-YYYY HH:mm')
          dto.retiraUsuario = this.formSolicitudesDto.retiraUsuario
          // dto.retiraOtro = this.formSolicitudesDto.retiraOtro
          dto.retiraDni = this.formSolicitudesDto.retiraDni
          dto.retiraNombre = this.formSolicitudesDto.retiraNombre
          dto.retiraApellido = this.formSolicitudesDto.retiraApellido
          dto.solicitanteDeleExtCode = solic.solicitanteDeleExtCode
          dto.solicitanteDelegacion = solic.solicitanteDelegacion
          dto.solicitanteNroAfiliado = solic.solicitanteNroAfiliado
          dto.solicitudAdjunto = [...solic.solicitudAdjunto]
          dtos.push(dto)
        }
      })
      return dtos
    },
    limpiarDatosRetiraOtro() {
      this.formSolicitudesDto.retiraDni = null
      this.formSolicitudesDto.retiraNombre = null
      this.formSolicitudesDto.retiraApellido = null
    },
    checkOnlyNumber(val) {
      const reg = new RegExp('^[0-9]*$')
      return reg.test(val)
    },
    validacionesExtra() {
      this.setSwallColor()
      const errores = []
      let adjuntosVacio = false
      let some = false
      this.solicitudes.forEach(solic => {
        if (solic.productoId !== null && solic.solicitudGenerada === false) {
          some = true
        }
        if (this.beneficio.solicitarAdjuntos) {
          if (solic.productoId !== null && solic.solicitudGenerada === false && solic.solicitudAdjunto.length === 0) {
            adjuntosVacio = true
          }
        }
      })
      if (some === false) {
        errores.push('Debe seleccionar un producto para al menos un beneficiario de la lista.')
      }
      if (adjuntosVacio === true) {
        errores.push('Debe adjuntar un documento para cada beneficiarios al cual haya seleccionado un producto')
      }
      return errores
    },
    validationFormSolicitudKit() {
      this.$refs.formSolicitudKit.validate().then(success => {
        let htmlErrors = 'a'
        const errores = this.validacionesExtra()
        if (errores.length > 0) {
          htmlErrors = `<ul style="color:${this.swallConfig.textColor}">`
          errores.forEach(error => {
            htmlErrors += `<li>${error}</li >`
          })
          htmlErrors += '</ul>'
        }
        if (errores.length === 0) {
          if (success) {
            // this.generarSolicitudes()
            this.generarSolicitudes()
            // this.generarSolicitudesMultiplesLlamadas()
          }
        } else {
          this.showMessageBig('Solicitudes', 'warning', htmlErrors)
        }
      })
    },
    backPanelBeneficios() {
      this.$router.push('/panelbeneficios')
    },
    validationStageOpcion() {
      // return (this.uploadedFile.name === null || this.uploadedFile.name === 'null' || this.uploadedFile.name === '') ? false : null
      return false
    },
    processFile(event) {
      const theFile = event.target.files[0]
      if (!this.validateFileSize(theFile.size)) {
        console.error('error upload')
        // this.toast('error', null, `<h4>Máximo tamaño permitido: ${this.config.tamanioMaximoAdjunto}MB</h4>`)
      } else {
        // this.uploadedFile.name = theFile.name
      }
      // this.uploadedFile.file = event.target.files[0]
    },
    validateFileSize(size) {
      const siszeMB = this.getMBfromBytes(size)
      if (siszeMB > this.config.tamanioMaximoAdjunto) {
        return false // not valid
      }
      return true // valid
    },
    showMessageSolicitudesGeneradas(titleMessage, icon_, message) {
      this.setSwallColor()
      const htmlText = `<p style="color:${this.swallConfig.textColor}">${message}</p>`

      this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        html: htmlText,
        icon: icon_,
        background: this.swallConfig.background,
        confirmButtonText: 'Cerrar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.backPanelBeneficios()
        }
      })
    },
    showMessageBig(titleMessage, icon_, htmlErrors) {
      this.setSwallColor()
      const htmlText = htmlErrors

      this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        // text: message,
        html: htmlText,
        icon: icon_,
        // showCancelButton: true,
        confirmButtonText: 'Cerrar',
        background: this.swallConfig.background,
        // cancelButtonText: 'No',

        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
        }
      })
    },
  },
}
</script>
